const CoffeeCup = () => (
  <div className="coffee">
    <span className="steam"></span>
      <div className="coffee-bg-lid"></div>
      <div className="coffee-rim"></div>
      <div className="coffee-rim-bt"></div>		
      <div className="coffee-color"></div>	
      <div className="coffee-cup"></div>
      <div className="coffee-shadow"></div>
  </div>
);

// https://codepen.io/bigdude/pen/dyggVM

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <CoffeeCup />
      </header>
    </div>
  );
}

export default App;
